// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-churchlaunch-js": () => import("./../../../src/pages/churchlaunch.js" /* webpackChunkName: "component---src-pages-churchlaunch-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-smallgroups-js": () => import("./../../../src/pages/smallgroups.js" /* webpackChunkName: "component---src-pages-smallgroups-js" */),
  "component---src-pages-teams-js": () => import("./../../../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

